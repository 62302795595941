import type { Shop } from "@/types";

interface Props {
  shop: Shop;
}

const ShopFavicon = ({ shop }: Props) => {
  const hostname = window.location.hostname;

  if (
    ["localhost", "acelords.com", "acelords.test", "www.acelords.com"].includes(
      hostname
    )
  ) {
    return null;
  }

  if (!shop.logo) {
    return (
      <>
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#543700" />
        <meta name="theme-color" content="#543700" />
      </>
    );
  }

  const link = document.createElement("link");
  const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');
  oldLinks.forEach((e) => e.parentNode?.removeChild(e));
  link.id = "dynamic-favicon";
  link.rel = "shortcut icon";
  link.href = shop.logo;
  document.head.appendChild(link);

  return <></>;
};

export default ShopFavicon;
